import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import useSearchSortFilter from '@utils/searchSortFilter';
import { TranslationContext } from '@utils/useTranslations';
import Container from '@components/Container';
import EmptyState from '@components/EmptyState';
import Footer from '@components/Footer';
import MediaGallery from '@components/MediaGallery';
import Navigation, { NavSpacer } from '@components/Navigation';
import SearchSortFilter from '@components/SearchSortFilter';
import Space from '@components/Space';
import SEO from '@components/SEO';

export const query = graphql`
  query GalleryQuery($id: String!) {
    gallery: sanityGallery(id: { eq: $id }) {
      published_vi
      published_zh_Hans
      imagesExtended {
        credit
        altText {
          en
          vi
          zh_Hans
          _type
        }
        image  {
          asset {
            fluid(
              maxWidth: 800,
              sizes: "(max-width: 600px) 100vw, 50vw"
              ){
              ...GatsbySanityImageFluid
            }
            fixed(width: 1200) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
      description {
        en
        vi
        zh_Hans
        _type
      }
      title {
        en
        vi
        zh_Hans
        _type
      }
      showChat
      isExcludedFromSearch
    }
  }
`;

const GalleryTemplate = (props) => {
  const { data: rawData } = props;
  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `gallery.published_${intl.locale}`) ? intl.locale : 'en',
  );
  const pageData = get(data, 'gallery', {});

  const translationContext = {
    vi: get(rawData, 'gallery.published_vi'),
    zh_Hans: get(rawData, 'gallery.published_zh_Hans'),
  };

  const imageItems = pageData.imagesExtended;

  const { controller, items } = useSearchSortFilter(imageItems, {
    initialFilter: {},
    initialSort: {},
    searchFields: ['title', 'caption'],
  });

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={props.banner} navItems={props.navItems} />
        <main>
          <SEO
            description={get(pageData, 'description')}
            image={get(pageData, 'images[0].asset.fixed')}
            title={get(pageData, 'title')}
            noIndex={pageData.isExcludedFromSearch}
          />
          <NavSpacer />
          <SearchSortFilter
            controller={controller}
            filters={[]}
            search={{ title: intl.formatMessage({ id: 'gallery.search.title' }) }}
          />
          <Space size="xsmall" />
          <Container centered>
            {items && items.length > 0 ? (
              <MediaGallery items={items} />
            ) : (
              <EmptyState />
            )}
          </Container>
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer showChat={pageData.showChat} />
      </TranslationContext.Provider>
    </>
  );
};

export default GalleryTemplate;
